/**
 * Script for setting 'theme' attribute on the document before app bootstraps.
 * Should be included via. project.json 'scripts: []' configuration
 */
(function () {
	// Load theme, default to light

	const isCookieEnabled = navigator.cookieEnabled;
	let theme;
	if (isCookieEnabled) {
		// Load theme, default to light
		theme = window.localStorage.getItem('theme');
	}
	if (!theme) {
		theme = 'light';
	}

	// Set theme attr on document
	window.document.documentElement.setAttribute('theme', theme);
})();
